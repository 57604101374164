import { useMutation } from "react-query";
import axios from "axios";
import { getHeader } from "../../../utility/utility";

async function doAction(postData) {
  try {
    const res = await axios.post(postData.path, postData.postData, getHeader());
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
}

export function useCommonPost() {
  const mutation = useMutation(doAction);
  return {
    ...mutation,
    doAction: mutation.mutate,
  };
}
