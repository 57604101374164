import React, { useContext } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, useTheme, useMediaQuery, LinearProgress } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { APP_HEADER_LOGO, VISITOR } from "../../../constants/constants";
import "./DashHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { getUser } from "../../../utility/utility";
import { UserContext } from "../../context/UserContext";
import AvatarMenu from "./AvatarMenu";
const NewDashHeader = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { user, loading, reloadUser } = useContext(UserContext);
  const notifications = [];
  const unseenCount = notifications.reduce(
    (count, notification) => count + (!notification.seen ? 1 : 0),
    0
  );
  const onMenuClick = () => {
    //setMobileMenuOpen((prev) => !prev);
  };
  if (loading) {
    return <LinearProgress />;
  }
  const userName =
    user.role === VISITOR
      ? `${user.firstName} ${user.lastName}`
      : `${user.companyName.value}`;
  const profileImage = user.profile_image;
  return (
    <div className="expp-dash-header-c">
      {isLargeScreen ? (
        <Link to={`/${user.role}`}>
          <img
            src={APP_HEADER_LOGO}
            alt="Imtex Logo"
            style={{
              marginLeft: "16px",
              height: 66,
              display: "block",
              cursor: "pointer",
            }}
          />
        </Link>
      ) : (
        <IconButton
          onClick={onMenuClick}
          color="primary"
          aria-label="menu"
          size="large"
        >
          <MenuIcon fontSize="inherhit" />
        </IconButton>
      )}
      <div style={{ flex: 1 }}></div>
      <div className="hd-2-item">
        <Link to={`/${getUser()?.role}/my-chats`}>
          <IconButton color="inherit">
            <ChatBubbleOutlineIcon sx={{ height: 20, width: 20 }} />
          </IconButton>
        </Link>
        <Link to={`/${getUser()?.role}/my-notifications`}>
          <IconButton color="primary" aria-label="notifications">
            <Badge badgeContent={unseenCount} color="secondary">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
        </Link>
        <AvatarMenu  userName={userName} profileImage={profileImage} />
      </div>
    </div>
  );
};

export default NewDashHeader;
