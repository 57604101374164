import React, { useEffect, useState } from "react";
import PreviewPageRegister from "./PreviewPageRegister";
import TempView from "./TempView";

const PreviewFormContainer = () => {
  const [state, setState] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    address: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",
    pin: "",
    website: "",
    telephone: "",
    fbCheck: false,
    instaCheck: false,
    twitterCheck: false,
    linkedInCheck: false,
    fbLink: "",
    instaLink: "",
    twitterLink: "",
    linkedInLink: "",
    designation: "",
    department: "",
    selectedEventSource: "",
    selectedIndustrySector: "",
    eventsList: [],
    selectedCategories: {},
  });
  const REG_LABELS = [
    {
      label: "Title",
      required: true,
      id: "title",
      type: "dropdown",
      size: "small",
      items: ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr.", "Prof."],
    },
    {
      label: "First Name",
      required: true,
      id: "firstName",
      type: "text",
      size: "small",
    },
    {
      label: "Last Name",
      required: true,
      id: "lastName",
      type: "text",
      size: "small",
    },
    {
      label: "Designation",
      required: true,
      id: "designation",
      type: "dropdown",
      size: "small",
      items: [
        "Chairman",
        "Managing Director",
        "CEO",
        "President",
        "Country Head",
        "Executive Director",
        "Vice President",
        "Sr. Vice President",
        "CTO",
        "COO",
        "CFO",
        "General Manager",
        "Sr. Director",
        "Director",
        "Deputy Director",
        "General Manager",
        "Manager",
        "Production Head",
        "Technician",
        "Engineer",
        "Machine Operator",
        "Production/ Plant/ Technical Head",
        "Trainees",
        "Consultant",
        "Executive",
        "Partner",
        "Advisor",
        "Programmer",
        "Contractor",
        "Engineer",
      ],
    },
    {
      label: "Phone",
      required: true,
      id: "phone",
      type: "phone",
      size: "small",
    },
    {
      label: "Email",
      required: true,
      id: "email",
      type: "email",
      size: "small",
    },
    {
      label: "Company Name",
      required: true,
      id: "companyName",
      type: "text",
      size: "small",
    },
    {
      label: "Address",
      required: true,
      id: "address",
      type: "text",
      size: "small",
    },
    {
      label: "Country",
      required: true,
      id: "country",
      type: "country",
      size: "small",
    },
    {
      label: "State",
      required: true,
      id: "state",
      type: "state",
      size: "small",
    },
    {
      label: "City",
      required: true,
      id: "city",
      type: "city",
      size: "small",
    },
    {
      label: "Pin",
      required: true,
      id: "pin",
      type: "text",
      size: "small",
    },
    {
      label: "Website",
      required: true,
      id: "website",
      type: "text",
      size: "small",
    },
    {
      label: "Telephone",
      required: true,
      id: "telephone",
      type: "text",
      size: "small",
    },
    {
      label: "Industry Sector",
      required: true,
      id: "industrySector",
      type: "dropdown",
      size: "small",
      items: [
        "Academia and Technical Institutions",
        "Aerospace / Civil Aviation",
        "Agriculture",
        "Aluminium Extrusion",
        "Auto Components",
        "Automation",
        "Automobile",
        "Biotechnology",
        "Capital Goods",
        "Chemicals",
        "Consumer Durables",
        "Defence and Railway Units",
        "Die & Mould",
        "Earthmoving Equipment",
        "Electrical and Electronics",
        "Embassies / High Commission",
        "Engineering Services",
        "Food Processing and Diary Equipment",
        "General Engineering",
        "Government Department",
        "Healthcare",
        "IT Industry",
        "Industrial Machinery",
        "Industry 4.0",
        "Infrastructure & Earth Moving Equipment",
        "Job Shops",
        "Logistics",
        "Machine Tools & Other Metal working Industries",
        "Maintenance & Services",
        "Material Handling",
        "Measuring Instruments & Gauges",
        "Medical Engineering",
        "Office Equipment and automation",
        "Oil & Gas equipment",
        "Paper Industries & Products",
        "Pharma Equipment",
        "Plant and Machinery",
        "Plastics Processing",
        "Polymers(Rubber Plastic PVC Resin Poly)",
        "Public Sector",
        "Renewable Energy",
        "Research and development organisations",
        "Space and Nuclear",
        "Telecom Equipment",
        "Textile Machinery & Products",
        "Tractors and Farm Equipment",
        "White and Brown Goods Manufacturers",
      ],
    },
    {
      label: "Job functions",
      required: true,
      id: "industrySector",
      type: "dropdown",
      size: "small",
      items: [
        "Business Management",
        "Distributor/Channel Partner/Agent",
        "Engineer/Controller/Technician",
        "Finance/Financial Management",
        "Human Resources/Administration",
        "IT",
        "Logistics/Material Management/Warehousing & Transportation",
        "Management",
        "Operations",
        "Operator/Supervisor/Machinist",
        "Procurement",
        "Production/Manufacturing",
        "Programmer/Analyst/Planner",
        "Quality",
        "R&D/Design",
        "Safety",
        "Sales/Marketing/Servicing",
        "Training",
      ],
    },
    {
      label: "How did you find out about this event?",
      required: true,
      id: "eventInfoSource",
      type: "dropdown",
      size: "small",
      items: [
        "Invitation from the Exhibitor",
        "Invitation from the Organizer",
        "Newspaper Advt.",
        "Social Media / Online Ads",
        "Outdoor Ads",
        "Trade Publications",
      ],
    },
    {
      label: "Check your options",
      required: true,
      id: "eventsList",
      type: "checkboxList",
      options: ["PMTX 2024", "FACTEQ 2024"],
    },
    {
      label: "Add the product categories that interest you",
      categories: [
        {
          MainCategory: "PMTX 2024",
          Categories: [
            "Cleaning Equipment",
            "Consumables-Cutting Tools, Grinding Wheels, Welding Electrodes",
            "Coolants, Oils, Lubricants, Packing Materials etc.",
            "Design Software - CAD/CAM/CAE",
            "Digital Manufacturing",
            "Energy Efficient Devices",
            "Enterprise Software-Low Cost Tally, ERP, Daily Work Management Software etc.",
            "Factory & Workshop Furniture",
            "Factory Storage Systems",
            "Financing",
            "Hand Tools",
            "Industrial Development Authorities",
            "Lighting Systems",
            "Logistics & Supply Chain Management",
            "Low Cost Automation",
            "Machine Tool Accessories",
            "Material Handling Equipment",
            "Measurement & Quality Control Equipment",
            "Metal Cutting Machines",
            "Metal Forming & Sheet Metal Working Machines",
            "Pollution Control Equipment",
            "Robotics & Automation",
            "Safety Equipment",
            "Shop Floor Maintenance",
            "Skill Building - Educational Institutes, Training Institutes etc.",
            "Technical Journals",
            "Welding Machines",
          ],
        },
        {
          MainCategory: "FACTEQ 2024",
          Categories: [
            "Air & Water Systems",
            "HVAC",
            "Material Storage & Transportation",
            "Factory Security & Industrial Safety Systems",
            "Electrical & Lighting Systems",
            "Sustainable & Environment Solutions",
            "Maintenance Systems & Equipment",
            "Information System & Hardware",
            "Factory Furniture",
            "Clean Rooms",
            "Building & Construction",
          ],
        },
      ],
    },
  ];
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const hostname = window.location.hostname;

  return (
    <div>
      {isPopupVisible && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <h3>Registration is closed</h3>
          </div>
        </div>
      )}
      {hostname === "imtex2024-imtma.expoplanner.in" ? (
        <TempView />
      ) : (
        <PreviewPageRegister
          state={state}
          setState={setState}
          handleStateChange={handleStateChange}
        />
      )}
    </div>
  );
};
const styles = {
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)", // semi-transparent black
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // to ensure the popup is on top of everything else
  },
  popupContent: {
    width: "300px",
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    textAlign: "center",
  },
};

export default PreviewFormContainer;
