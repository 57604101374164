import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import CountryAutoComplete from "../widgets2024/CountryAutoComplete";

const countries = ["United States", "Canada", "Australia", "Germany", "France"]; // Add more countries as needed
const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
const halls = ["Hall 1", "Hall 2", "Hall 3", "Hall 4", "Hall 5"]; // Add more halls as needed
const FilterDialogMui = ({
  selectedCountry,
  setSelectedCountry,
  open,
  setOpen,
  selectedHall,
  setSelectedHall,
  selectedLetter,
  setSelectedLetter,
  isVisitor = false,
  catLabel = "Filter by Hall",
  categories = ["Hall 1", "Hall 2", "Hall 3", "Hall 4", "Hall 5"],
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelectHall = (hall) => {
    if (hall === selectedHall) {
      setSelectedHall("");
    } else {
      setSelectedHall(hall);
    }
  };

  const onCountrySelect = (e, newV) => {
    setSelectedCountry(newV ? newV?.name : "");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { maxWidth: "500px", width: "100%" } }}
    >
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Box mb={2}>
            <CountryAutoComplete
              onChange={onCountrySelect}
              size="small"
              label="Filter by country"
            />
          </Box>
          <Box mb={2}>
            <Autocomplete
              size="small"
              options={alphabets}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Alphabet"
                  variant="outlined"
                />
              )}
              value={selectedLetter}
              onChange={(event, newValue) =>
                setSelectedLetter(newValue === null ? "" : newValue)
              }
            />
          </Box>
          <Box mb={2}>
            <Typography variant="h6" fontSize={17} mb={1}>
              {catLabel}
            </Typography>
            {categories.map((hall) => (
              <Chip
                key={hall}
                label={hall}
                onClick={() => handleSelectHall(hall)}
                color={selectedHall === hall ? "primary" : "default"}
                style={{ marginRight: 4, marginBottom: 4 }}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialogMui;
