import React, { useState } from "react";
import VisitorCard from "../../../../widgets/VisitorCard";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import SearchBar from "../../../../widgets/searchBar";
import { API_ENDPOINT } from "../../../../constants/constants";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import { LoadingButton } from "@mui/lab";
import FilterListIcon from "@mui/icons-material/FilterList";
import useApiEndpoint from "../../../events/hooks/useApiEndpoint";
import FilterDialogMui from "../../../../widgets/FilterDialogMui";
const Visitors = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [category, setCategory] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");
  const { data, isLoading, isError, error } = useApiEndpoint(
    `${API_ENDPOINT}user/get-visitors?page=${page}&sq=${sq}&category=${category}&country=${selectedCountry}&letter=${selectedLetter}`
  );
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  return (
    <Box>
      <CustomCircularP show={isLoading} />
      <FilterDialogMui
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        selectedHall={category}
        setSelectedHall={setCategory}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
        open={openFilter}
        setOpen={setOpenFilter}
        catLabel="Filter by Category"
        categories={["PMTX 2024", "FACTEQ 2024"]}
      />
      <Grid
        pl={2}
        pr={2}
        mt={2}
        mb={2}
        gap={2}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" fontWeight={"bold"}>
            Visitors{" "}
          </Typography>
        </Grid>
        <Grid item xs style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <SearchBar
            onSearch={onItemSearch}
            label="Search Visitors"
            size="7px"
            boxAlign="inline-flex"
            mystyle={{
              display: "inline-flex",
              backgroundColor: "white",
              width: "276px",
            }}
            cstyle={{ flex: 1 }}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            onClick={() => setOpenFilter(true)}
            startIcon={<FilterListIcon />}
            variant="contained"
          >
            Filter
          </LoadingButton>
        </Grid>
      </Grid>
      <div style={{ display: "inline-block", gap: "12px" }}>
        {data?.data?.map((exh, i) => (
          <VisitorCard key={i} item={exh} />
        ))}
        {data?.data?.length === 0 && (
          <div className="items-not-found">No Data Yet</div>
        )}
      </div>
      <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          count={Math.ceil(data?.totalItems / 30)}
          page={page}
          onChange={onPageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Visitors;
