import React, { useState } from "react";
import ExhibitorCard from "../../../../widgets/ExhibitorCard";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import SearchBar from "../../../../widgets/searchBar";
import { API_ENDPOINT } from "../../../../constants/constants";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import { LoadingButton } from "@mui/lab";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterDialogMui from "../../../../widgets/FilterDialogMui";
import useApiEndpoint from "../../../events/hooks/useApiEndpoint";

const Exhibitors = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedHall, setSelectedHall] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");
  const { data, isLoading, isError, error } = useApiEndpoint(
    `${API_ENDPOINT}user/get-exhibitors?page=${page}&sq=${sq}&hall=${selectedHall}&country=${selectedCountry}&letter=${selectedLetter}&`
  );

  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  return (
    <div>
      <CustomCircularP show={isLoading} />
      <FilterDialogMui
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        selectedHall={selectedHall}
        setSelectedHall={setSelectedHall}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
        open={openFilter}
        setOpen={setOpenFilter}
      />
      <div>
        <Grid
          pl={2}
          pr={2}
          mt={2}
          mb={2}
          gap={2}
          container
          justifyContent="flex-start" // Align items to the start
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              Exhibitors
            </Typography>
          </Grid>
          <Grid item xs style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <SearchBar
              onSearch={onItemSearch}
              label="Search Exhibitors"
              size="7px"
              boxAlign="inline-flex"
              mystyle={{
                display: "inline-flex",
                backgroundColor: "white",
                width: "276px",
              }}
              cstyle={{ flex: 1 }}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={() => setOpenFilter(true)}
              startIcon={<FilterListIcon />}
              variant="contained"
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {data?.data?.map((exh, i) => (
          <ExhibitorCard key={i} item={exh} />
        ))}
        <Box mt={2} mb={2} justifyContent={"center"} display={"flex"}>
          <Pagination
            count={Math.ceil(data?.totalItems / 30)}
            page={page}
            onChange={onPageChange}
            color="primary"
          />
        </Box>
      </div>
    </div>
  );
};

export default Exhibitors;
