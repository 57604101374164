import React, { useEffect, useState } from "react";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import CategoriesEdit from "./edit/CategoriesEdit";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import { toast } from "react-toastify";
import { getHeader } from "../../../../utility/utility";
import axios from "axios";
import { PROFILE_EDIT_API } from "../ProfileDetails";
import { toastError } from "../../../../utils2024/utils";
import SelectedProductCategories from "../../common/SelectedProductCategories";

const Categories = ({ profile, isView = false, setProfile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [curCat, setCurCat] = useState("");
  const onCategoryDelete = (itemToDelete) => {
    setCurCat(itemToDelete);
    setShowDelete(true);
  };
  const onEdit = () => {
    setShowEdit(true);
  };
  const onYesDelete = () => {
    setShowDelete(false);
    const updatedCategories = { ...profile.selectedCategories };
    updatedCategories[curCat.categoryName] = updatedCategories[
      curCat.categoryName
    ].filter((item) => item !== curCat.itemToDelete);

    setDeleting(true);
    axios
      .post(
        PROFILE_EDIT_API,
        {
          selectedCategories: {
            label: "Product Categories",
            value: updatedCategories,
          },
        },
        getHeader()
      )
      .then((res) => {
        setDeleting(false);
        toast.success(res.data.message);
        setProfile((prevState) => ({
          ...prevState,
          selectedCategories: updatedCategories,
        }));
      })
      .catch((error) => {
        setDeleting(false);
        toastError(error);
      });
  };
  return (
    <div className="profile-info-c">
      {deleting && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // center horizontally
            alignItems: "center", // center vertically
            height: "100vh", // take full viewport height
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <RdMyModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        modalC={
          <CategoriesEdit
            profile={profile}
            setProfile={setProfile}
            setShow={setShowEdit}
          />
        }
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDelete}
        show={showDelete}
        setShow={setShowDelete}
      />
      <HeaderLabel
        onEdit={null}
        showEdit={!isView}
        label={"Product Categories"}
      />

      <div>
        {
          <SelectedProductCategories
            selectedCategories={profile.productCategories}
          />
        }
      </div>
    </div>
  );
};

export default Categories;
