import { useQuery } from "react-query";
import axios from "axios";
import { getHeader } from "../../../utility/utility";

const doAction = async (path) => {
  try {
    const res = await axios.get(path, getHeader());
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useApiEndpoint = (path) => {
  return useQuery([path], () => doAction(path), {
    staleTime: 1 * 60 * 1000,
    retry: 2,
  });
};

export default useApiEndpoint;
