import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import RdIconContainer from "./RdIconContainer";
import { ICON_TYPE_NON_IMAGE } from "../constants/constants";
import EditBtn from "./actionBtn/editBtn";
import { Stack } from "@mui/material";

const HeaderLabel = ({ label, onEdit, labelStyle = {}, showEdit = true }) => {
  return (
    <Stack direction={"row"} mt={1} mb={1}>
      <div
        style={{
          fontWeight: 600,
          color: "#185399",
          flex: 1,
          alignSelf: "center",
          ...labelStyle,
        }}
      >
        {label}
      </div>
      {showEdit && <EditBtn onEdit={onEdit} />}
    </Stack>
  );
};

export default HeaderLabel;
