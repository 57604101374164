import React, { useState } from "react";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import ProfileInfoCard from "../../../../widgets/ProfileInfoCard";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import CompanyInfoEdit from "./edit/CompanyInfoEdit";

const Company = ({ profile, isView = false, setProfile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const onEditCompany = () => {
    setShowEdit(true);
  };
  return (
    <div className="profile-info-c">
      <RdMyModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        modalC={
          <CompanyInfoEdit
            profile={profile}
            setProfile={setProfile}
            setShow={setShowEdit}
          />
        }
      />
      <HeaderLabel
        showEdit={!isView}
        onEdit={onEditCompany}
        label="Company Information"
      />
      <div className="rd-items-three">
        <ProfileInfoCard
          infoCard={{ label: "Company Name", value: profile.companyName }}
        />
        <ProfileInfoCard
          infoCard={{ label: "Address", value: profile.address }}
        />
        <ProfileInfoCard
          infoCard={{
            label: "Country",
            value: profile?.country?.name,
          }}
        />
        <ProfileInfoCard
          infoCard={{
            label: "State",
            value: profile?.stateRegion?.name,
          }}
        />
        <ProfileInfoCard
          infoCard={{ label: "City", value: profile?.city?.name }}
        />
        <ProfileInfoCard infoCard={{ label: "Pin", value: profile.pinZip }} />
        <ProfileInfoCard
          infoCard={{ label: "Telephone", value: profile.telephoneNo }}
        />
        <ProfileInfoCard
          infoCard={{ label: "Website", value: profile.website }}
        />
      </div>
    </div>
  );
};

export default Company;
