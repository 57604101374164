import { useQuery } from "react-query";
import axios from "axios";
import { VISITOR_API } from "../../../../constants/constants";
const fetchData = async (ccode) => {
  try {
    const res = await axios.get(
      `${VISITOR_API}get-cities-by-country?c=${ccode}`
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useCitiesCountry = (ccode) => {
  return useQuery(["get-cities-by-country", ccode], () => fetchData(ccode), {
    enabled: !!ccode,
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 3, // If the query fails, it will retry up to 3 times
  });
};

export default useCitiesCountry;
