import React, { useEffect, useState } from "react";
import { Stage, Layer, Rect, Line } from "react-konva";

const TestKonva = () => {
  const [elements, setElements] = useState({ lines: [], rectangles: [] });

  function parseSVG(svgContent) {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");

    // Extract lines from the SVG
    const lines = Array.from(svgDoc.querySelectorAll("line")).map((line) => ({
      points: [
        parseFloat(line.getAttribute("x1")),
        parseFloat(line.getAttribute("y1")),
        parseFloat(line.getAttribute("x2")),
        parseFloat(line.getAttribute("y2")),
      ],
      stroke: line.getAttribute("stroke") || "black",
    }));

    // Extract rectangles from the SVG
    const rectangles = Array.from(svgDoc.querySelectorAll("rect")).map(
      (rect) => ({
        x: parseFloat(rect.getAttribute("x") || 0),
        y: parseFloat(rect.getAttribute("y") || 0),
        width: parseFloat(rect.getAttribute("width")),
        height: parseFloat(rect.getAttribute("height")),
        fill: rect.getAttribute("fill") || "transparent",
        stroke: rect.getAttribute("stroke") || "black",
        opacity: parseFloat(rect.getAttribute("opacity") || 1),
      })
    );

    return { lines, rectangles };
  }

  useEffect(() => {
    const svgContent = `<svg width="411" height="431" viewBox="0 0 411 431" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="411" height="431" fill="white"/>
    <rect x="29.5" y="37.5" width="348" height="370" fill="white"/>
    <rect x="29.5" y="37.5" width="348" height="370" stroke="black"/>
    <rect x="29.5" y="37.5" width="348" height="370" stroke="black" stroke-opacity="0.2"/>
    <line x1="29.0015" y1="253.5" x2="378.001" y2="254.564" stroke="black"/>
    <line x1="28.9985" y1="126.5" x2="377.998" y2="125.471" stroke="black"/>
    <line x1="114.5" y1="254" x2="114.5" y2="408" stroke="black"/>
    <line x1="225.5" y1="255" x2="225.5" y2="409" stroke="black"/>
    <line x1="173.5" y1="126" x2="173.5" y2="254" stroke="black"/>
    <line x1="277.5" y1="127" x2="277.5" y2="255" stroke="black"/>
    <line x1="225.5" y1="37" x2="225.5" y2="126" stroke="black"/>
    </svg>`;

    const parsedElements = parseSVG(svgContent);
    console.log(parsedElements);
    setElements(parsedElements);
  }, []);

  return (
    <div>
      <Stage width={411} height={431}>
        <Layer>
          {elements.rectangles.map((rect, index) => (
            <Rect
              key={`rect-${index}`}
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              fill={rect.fill}
              stroke={rect.stroke}
              opacity={rect.opacity}
            />
          ))}
          {elements.lines.map((line, index) => (
            <Line
              key={`line-${index}`}
              points={line.points}
              stroke={line.stroke}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default TestKonva;
