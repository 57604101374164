import React from "react";
import vBg from "../../../assets/visitor_bg2.png"; // Path to your image
import { Alert, Box } from "@mui/material";
import QRCode from "react-qr-code";
import { API_ENDPOINT, BADGE_BG, USER } from "../../../constants/constants";
import MuPb from "../../../widgets/MuPb";
import useUserUrlGet from "../../events/hooks/useUserUrlToken";
import { useQuery } from "../../../utility/utility";
export const ViewBadgeMobile = () => {
  const query = useQuery();
  const id = query.get("id");
  const HEADER = {
    headers: {
      "content-type": "application/json",
      token: id,
    },
  };
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useUserUrlGet(`${API_ENDPOINT}${USER}/get-profile`, HEADER);
  const style = {
    badgeContainer: {
      marginTop: "16px",
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      width: "8cm",
      height: "11.2cm",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      color: "black",
      boxSizing: "border-box",
    },
    detailsContainer: {
      textAlign: "center",
      marginTop: "4cm",
    },
    qrCode: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      marginTop: "0.5cm", // Adjust the margin as needed
      width: "2cm", // Adjust size as needed
      height: "2cm", // Adjust size as needed
    },
  };

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{"Could not load due to some error"}</Alert>;
  }
  return (
    <div style={style.badgeContainer}>
      <div style={style.detailsContainer}>
        <img
          src={BADGE_BG}
          alt="Badge Background"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "3.5cm",
            left: ".2cm",
            right: ".2cm",
            bottom: "1cm",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "16px", mt: 6 }}>
            {`${user?.firstName?.toUpperCase()} ${user?.lastName?.toUpperCase()}`}
          </Box>
          <Box sx={{ fontWeight: "bold", fontSize: "17px", mt: 1 }}>
            {`${user?.companyName?.toUpperCase()}`}
          </Box>
          <Box mt={2}>
            <QRCode value={user?.barcode_id} size={116} />
          </Box>
        </Box>
      </div>
      <div />
    </div>
  );
};
