import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import useCountries from "./hooks/useCountries";

function CountriesCom({ state, handleStateChange, label = "Select Country*" }) {
  const { data, isLoading, isError, error } = useCountries(); // Fetch visitors for page 1
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  useEffect(() => {
    if (data) {
      const IndiaObj = state.selectedCountry
        ? data.find((dt) => dt.isoCode === state.selectedCountry.isoCode)
        : data.find((dt) => dt.isoCode === "IN");
      handleStateChange("selectedCountry", IndiaObj);
    }
  }, [data]);
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="selectedCountry"
        value={state.selectedCountry}
        label={label}
        onChange={handleInputChange}
      >
        {data.map((target) => (
          <MenuItem key={target.isoCode} value={target}>
            {target.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CountriesCom;
