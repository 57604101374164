import React, { useContext, useState } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";
import { VISITOR } from "../../../constants/constants";
import Dashboard from "./Dashboard";
import { VISITOR_MENU_LIST, deleteUser } from "../../../utility/utility";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import ProfileDetails from "./ProfileDetails";
import NewDashHeader from "../header/NewDashHeader";
import SideMenuList from "../exhibitor/profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import MobileSideMenuList from "../exhibitor/profile/MobileSideMenuList";
import { Alert } from "@mui/material";
import { UserContext } from "../../context/UserContext";

const VisitorDashboard = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const { user, loading, reloadUser } = useContext(UserContext);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const onLogout = () => {
    deleteUser();
    navigate("/login");
  };
  const onMenuClick = () => {
    setShowSideMenu(!showSideMenu);
  };
  if (loading) {
    return <MuPb />;
  }
  if (!user) {
    return <Alert severity="error">No user data available!</Alert>;
  }
  const notificationsA = [
    // { title: "Welcome to IMTEX FORMING 2024", subtitle: "" },
    // {
    //   title: `You changed password ${timeAgo(user.createdOn)}`,
    //   subtitle: timeAgo(user.createdOn),
    // },
  ];

  return (
    <div className="rd-expo-dash-c">
      <NewDashHeader />
      <div className="rd-dashboard-c">
        <div style={{ display: "flex" }}>
          <SideMenuList
            showSideMenu={showSideMenu}
            onLogout={onLogout}
            category={VISITOR}
            menuList={VISITOR_MENU_LIST}
            page={page}
          />
          <MobileSideMenuList
            showSideMenu={showSideMenu}
            setShowSideMenu={setShowSideMenu}
            onLogout={onLogout}
            category={VISITOR}
            menuList={VISITOR_MENU_LIST}
            page={page}
          />
          <div style={{ flex: 1 }}>
            {/* {page === "dashboard" || page === undefined ? (
              <Dashboard user={user} />
            ) : page === "profile" ? (
              <ProfileDetails user={user} />
            ) : page === "explore" ||
              page === "exhibitors" ||
              page === "products" ||
              page === "ai-matching" ||
              page === "visitors" ? (
              <Explore page={page} />
            ) : page === "floor-plan" ? (
              <FloorPlan />
            ) : page === "all-meetings" ? (
              <AllMeetings user={user} />
            ) : page === "my-schedule" ? (
              <MySchedule />
            ) : page === "print-badge" ? (
              <PrintBadge user={user} />
            ) : page === "favorites" ? (
              <MyFavorites />
            ) : page === "reports" ? (
              <Reports />
            ) : page === "settings" ? (
              <Settings />
            ) : page === "view-profile" ? (
              <ProfileViewByID />
            ) : page === "profile-viewers" ? (
              <ProfileViewers />
            ) : page === "product-view" ? (
              <ProductContainer />
            ) : page === "meeting-scheduler" ? (
              <ScheduleMeeting />
            ) : page === "chat" ? (
              <ChatApp />
            ) : (
              <></>
            )} */}
            <Routes>
              <Route path="" element={<Dashboard />} />
              <Route path="profile" element={<ProfileDetails />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default VisitorDashboard;
