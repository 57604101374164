import React, { useEffect, useState } from "react";

import weldexpo from "../../assets/weldexpo1.png";
import dm from "../../assets/dm1.png";
import imtex from "../../assets/imtex1.png";
import bn1 from "../../assets/bn1.jpg";
import bn2 from "../../../assets/vad3.jpg";
import pmtx2 from "../../../assets/pmtx_ad_2.png";
import mad1 from "../../../assets/mad5.png";
import mad2 from "../../../assets/mad2.jpg";
import mad3 from "../../../assets/mad3.jpg";

import { Button, Hidden } from "@mui/material";
import "./PreviewPageRegister.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "./Footer";
import "react-phone-number-input/style.css";
import RegHeader from "../headers/regHeader";
import RegInputs from "./RegInputs";
import PreviewComponent from "./PreviewComponent";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { API_ENDPOINT, EVENT_API } from "../../../constants/constants";
import { isValidEmail } from "../../utils/utils";
import TermsAndConditions from "../../pages/termsConditions";
import { getDbHeader, toastError } from "../../../utils2024/utils";

const PreviewPageRegister = ({ state, setState, handleStateChange }) => {
  const navigate = useNavigate();
  const REG_INPUTS = "reg_inputs";
  const REG_PREVIEW = "reg_preview";
  const [currentView, setCurrentView] = useState(REG_INPUTS);
  const [emailVerified, setEmailVerified] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const [capthcaClicked, setCaptchaClicked] = useState(true);

  const handleCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  useEffect(() => {
    // Find the existing og:image meta tag
    const metaTag = document.querySelector('meta[property="og:image"]');
    const imageUrl =
      "https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/Cover+Image.jpg";
    // If the tag exists, update its content attribute
    if (metaTag) {
      metaTag.setAttribute("content", imageUrl);
    } else {
      // If the tag does not exist, create it and append to <head>
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("property", "og:image");
      newMetaTag.setAttribute("content", imageUrl);
      document.getElementsByTagName("head")[0].appendChild(newMetaTag);
    }
    document.title = "PUNE 2024 | FACTEQ 2024";
  }, []);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const rightImages = [
    {
      src: "https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/PMTX2024-Ad1.webp",
      link: "https://www.imtex.in/",
    },
    {
      src: "https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX2024-Ad2.webp",
      link: "https://www.imtex.in/",
    },
    { src: weldexpo, link: "https://www.weldexpo.in/" }, //3, 5

    { src: pmtx2, link: "https://www.mtx.co.in/" },
    {
      src: "https://s3.ap-south-1.amazonaws.com/expoplanner.in/PCA+_+web+ad+312X390+pix.png",
      link: "http://www.productivity.imtma.in",
    },
    { src: bn2, link: "https://maps.app.goo.gl/eaHigpHYBxf1ppHL7/" },
    { src: mad2, link: "https://www.mtx.co.in/" },
    { src: mad3, link: "https://www.mtx.co.in/" },
  ];
  const submitBtnStyles = {
    button: {
      backgroundColor: "#FF5722",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#f88b68", // Darker shade of the original color
      },
      "&.Mui-disabled": {
        backgroundColor: "grey", // You can choose a specific shade of grey if needed
        color: "#FFFFFF",
      },
    },
  };

  const onClickPrev = () => {
    setCurrentView(REG_INPUTS);
  };
  const onClickSubmit = async () => {
    if (currentView === REG_INPUTS) {
      if (state.title.trim() === "") {
        let titleField = document.getElementsByName("title")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        return;
      } else if (state.firstName.trim() === "") {
        toast.error("Please enter First Name");
        let titleField = document.getElementsByName("firstName")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        return;
      } else if (state.lastName.trim() === "") {
        toast.error("Please enter Last Name");
        let titleField = document.getElementsByName("lastName")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        return;
      } else if (state.designation.trim() === "") {
        toast.error("Please select Designation");
        let titleField = document.getElementsByName("designation")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        return;
      } else if (state.phone === undefined || state.phone.trim() === "") {
        toast.error("Please enter Phone Number");
        let titleField = document.getElementsByName("phone")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        return;
      } else if (state.email.trim() === "") {
        let titleField = document.getElementsByName("email")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please enter Email");
        return;
      } else if (!isValidEmail(state.email)) {
        let titleField = document.getElementsByName("email")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please enter valid Email");
        return;
      } else if (state.companyName.trim() === "") {
        let titleField = document.getElementsByName("companyName")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please enter Company Name");
        return;
      } else if (!capthcaClicked) {
        toast.error("Please verify captcha");
        return;
      } else if (state.selectedCountry === "") {
        let titleField = document.getElementsByName("selectedCountry")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select Country");
        return;
      } else if (state.selectedState === "") {
        let titleField = document.getElementsByName("selectedState")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select State");
        return;
      } else if (state.selectedCity === "") {
        let titleField = document.getElementsByName("selectedCity")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select City");
        return;
      } else if (state.pin.trim() === "") {
        let titleField = document.getElementsByName("pin")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select Pin");
        return;
      } else if (state.selectedIndustrySector === "") {
        document
          .getElementById("industrySector")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        toast.error("Please select Industry Sector");
        return;
      } else if (state.address.trim() === "") {
        let titleField = document.getElementsByName("address")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please Enter Address");
        return;
      } else if (state.department === "") {
        let titleField = document.getElementsByName("department")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select department");
        return;
      } else if (state.selectedEventSource === "") {
        let titleField = document.getElementsByName("selectedEventSource")[0];
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.parentElement.style.border = "1px solid red";
        setTimeout(() => {
          titleField.parentElement.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please tell how did you find out about this event");
        return;
      } else if (state.eventsList.length === 0) {
        let titleField = document.getElementById("events_list");
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.style.border = "1px solid red";
        setTimeout(() => {
          titleField.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select what you will be visiting");
        return;
      } else if (state.selectedCategories.length === 0) {
        let titleField = document.getElementById("selectedCategories");
        titleField.scrollIntoView({ behavior: "smooth", block: "center" });
        titleField.style.border = "1px solid red";
        setTimeout(() => {
          titleField.style.border = ""; // Revert to the original style
        }, 3000);
        toast.error("Please select product categories that interest you");
        return;
      } else {
        setCurrentView(REG_PREVIEW);
      }
    } else {
      if (isTermsChecked) {
        setRegistering(true);
        try {
          const response = await axios.post(
            `${EVENT_API}visitor-register`,
            state,
            getDbHeader()
          );
          let reg_id = response.data.reg_id;
          navigate(`/reg-qr/${reg_id}`);
        } catch (error) {
          toastError(error);
        } finally {
          setRegistering(false);
        }
      } else {
        toast.info("Please check terms and conditions before submitting");
      }
    }
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <RegHeader />

      <div className="v-form-ad-c">
        <div className="v-form-area">
          <Hidden smUp>
            <a href="https://www.mtx.co.in/" target="_blank">
              <img src={mad1} alt="Mobile Banner" width="100%" />
            </a>
          </Hidden>
          <div className="my-reg-title">VISITOR REGISTRATION</div>

          {currentView === REG_INPUTS ? (
            <RegInputs
              setCaptchaClicked={setCaptchaClicked}
              state={state}
              setState={setState}
              handleStateChange={handleStateChange}
              emailVerified={emailVerified}
              setEmailVerified={setEmailVerified}
            />
          ) : (
            <PreviewComponent state={state} />
          )}
          {currentView === REG_PREVIEW && (
            <div style={{ margin: "16px" }}>
              <TermsAndConditions />
              <label>
                <input
                  type="checkbox"
                  checked={isTermsChecked}
                  onChange={handleCheckboxChange}
                />
                I agree
              </label>
            </div>
          )}

          <div
            style={{
              marginTop: "16px",
              marginBottom: "24px",
              maxWidth: "320px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {currentView === REG_PREVIEW && (
              <Button
                variant="outlined"
                onClick={onClickPrev}
                fullWidth
                style={{ flex: 1, marginRight: "12px" }}
              >
                Edit
              </Button>
            )}
            <LoadingButton
              disabled={!isTermsChecked && currentView === REG_PREVIEW}
              loading={registering}
              variant="contained"
              onClick={onClickSubmit}
              fullWidth
              sx={{
                ...submitBtnStyles.button,
                flex: 1,
                marginLeft: currentView === REG_PREVIEW ? "12px" : "auto",
              }}
            >
              {currentView === REG_PREVIEW ? "Submit" : "Next"}
            </LoadingButton>
          </div>
          {/* <div style={{ margin: "16px 24px 30px 16px", fontWeight: 500 }}>
            <b>NOTE:</b>
            <span style={{ color: "rgb(5, 64, 84)" }}>
              {" "}
              SHUTTLE SERVICE IS AVAILABLE ON SHOW DAY (August 24–27, 2023) FROM
              GATE-1 AND GATE-10 AT PRAGATI MAIDAN, NEW DELHI.
            </span>
          </div> */}
          <Hidden smUp>
            <a href="https://www.imtex.in/" target="_blank">
              <img src={mad3} alt="Mobile Banner" width="100%" />
            </a>
          </Hidden>
        </div>
        <div className="v-ad-area">
          <Slider {...sliderSettings}>
            {rightImages.slice(0, 1).map((image, index) => (
              <a
                key={index}
                href={image.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={image.src}
                  style={{ display: "block", width: "100%" }}
                  alt={`Image ${index + 1}`}
                />
              </a>
            ))}
          </Slider>
          <br />
          <Slider {...sliderSettings}>
            {rightImages.slice(1, 2).map((image, index) => (
              <a
                key={index}
                href={image.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={image.src}
                  style={{ display: "block", width: "100%" }}
                  alt={`Image ${index + 1}`}
                />
              </a>
            ))}
          </Slider>
          <br />
          <Slider {...sliderSettings}>
            {rightImages.slice(5, 6).map((image, index) => (
              <a
                key={index}
                href={image.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={image.src}
                  style={{ display: "block", width: "100%" }}
                  alt={`Image ${index + 1}`}
                />
              </a>
            ))}
          </Slider>
          {/* <Slider {...sliderSettings}>
            {rightImages.slice(6, 7).map((image, index) => (
              <a
                key={index}
                href={image.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={image.src}
                  style={{ display: "block", width: "100%" }}
                  alt={`Image ${index + 1}`}
                />
              </a>
            ))}
          </Slider> */}
          {/* <Slider {...sliderSettings}>
            {rightImages.slice(4, 7).map((image, index) =>
              image.link !== "" ? (
                <a
                  key={index}
                  href={image.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={image.src}
                    style={{ display: "block", width: "100%" }}
                    alt={`Image ${index + 1}`}
                  />
                </a>
              ) : (
                <img
                  src={image.src}
                  style={{ display: "block", width: "100%" }}
                  alt={`Image ${index + 1}`}
                />
              )
            )}
          </Slider> */}
          {/* <br />
          <Slider {...sliderSettings}>
            {rightImages.slice(5, 7).map((image, index) =>
              image.link !== "" ? (
                <a
                  key={index}
                  href={image.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={image.src}
                    style={{ display: "block", width: "100%" }}
                    alt={`Image ${index + 1}`}
                  />
                </a>
              ) : (
                <img
                  src={image.src}
                  style={{ display: "block", width: "100%" }}
                  alt={`Image ${index + 1}`}
                />
              )
            )}
          </Slider> */}
          {/* <img src={rightImages[3].src} style={{ display: 'block', width: '100%', cursor: 'pointer' }} alt="Image 4" onClick={() => window.open(rightImages[3].link, '_blank')} /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PreviewPageRegister;
