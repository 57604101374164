import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BackBtn from "../admin/widgets/BackBtn";

const notifications = [
  {
    id: 2,
    message: "Ratul Hoineki requested a meeting",
    timestamp: "2 days ago",
  },
  { id: 3, message: "Your password was changed", timestamp: "Yesterday" },
];

const NotificationsList = () => {
  return (
    <Paper
      sx={{ maxWidth: 700, ml: "auto", mr: "auto", mt: 2, padding: "20px" }}
    >
      <BackBtn />
      <Typography variant="h6" component="div" gutterBottom>
        Notifications
      </Typography>
      <List>
        {notifications.map((notification, index) => (
          <React.Fragment key={notification.id}>
            <ListItem>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText
                primary={notification.message}
                secondary={notification.timestamp}
              />
            </ListItem>
            {index < notifications.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default NotificationsList;
