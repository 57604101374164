import React from "react";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import {
  Avatar,
  Box,
  Typography,
  Stack,
  Tooltip,
  Card,
  CardActionArea,
} from "@mui/material";
import { VISITOR } from "../constants/constants";
import { Link } from "react-router-dom";

const MeetingCard = ({ meetingItem }) => {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const getFullName = (person) => `${person.firstName} ${person.lastName}`;
  const getName = (user) => {
    if (user.role === VISITOR) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return `${user.companyName.value}`;
    }
  };
  return (
    <Card
      sx={{
        marginTop: "12px",
        "&:hover": {
          backgroundColor: "#f4f4f4", // Change this color for hover effect
        },
      }}
    >
      <CardActionArea>
        <Box
          sx={{
            border: "1px solid #f9e6d3",
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mt: 1 }}>
            {meetingItem.subject}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box sx={{ color: "#777" }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <AlarmOnIcon fontSize="small" />
                <Typography variant="body2">
                  {formatTime(meetingItem.selectedDate)} |{" "}
                  {meetingItem.duration}mins
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                  {formatDate(meetingItem.selectedDate)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <AlignVerticalBottomIcon fontSize="small" />
                <Typography variant="body2">
                  {meetingItem.meeting_type}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ alignSelf: "center", pl: 2, color: "#777" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                Participants
              </Typography>
              <Stack direction="row" spacing={1}>
                <Tooltip title={getName(meetingItem.sender_info[0])}>
                  <Avatar
                    src={meetingItem.sender_info[0].profile_image}
                    alt={getName(meetingItem.sender_info[0])}
                  />
                </Tooltip>
                <Tooltip title={getName(meetingItem.recipient_info[0])}>
                  <Avatar
                    src={meetingItem.recipient_info[0].profile_image}
                    alt={getName(meetingItem.recipient_info[0])}
                  />
                </Tooltip>
              </Stack>
            </Box>
          </Stack>
          <Box mt={2} display={"flex"} justifyContent={"end"}>
            <a
              href={"https://service.qabdad.com/rooms/cs9-ua1-gmr-bgs/join"}
              target="_blank"
            >
              Meeting link
            </a>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default MeetingCard;
