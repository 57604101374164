import { Chip, CircularProgress, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "../../../constants/constants";
import { getDbHeader, toastError } from "../../../utils2024/utils";

const SelectedProductCategories = ({ selectedCategories }) => {
  const { loading, setLoading } = useState(true);
  const [categories, setCategories] = useState();

  useEffect(() => {
    loadCategories();
  }, []);
  async function loadCategories() {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}event/get-product-categories`,
        getDbHeader()
      );
      setCategories(data);
    } catch (error) {
      toastError(error);
    }
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      {selectedCategories.map((v, i) => (
        <Chip label={v} variant="outlined" sx={{ ml: 1 }} />
      ))}
    </div>
  );
};

export default SelectedProductCategories;
