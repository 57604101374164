import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PUNE_CATEGORIES } from "../../../constants/constants";

function CategoryAccordion({ state, setState }) {
  const handleSubCategoryChange = (mainCategory, subCategory) => {
    const currentSelected = state.selectedCategories[mainCategory] || [];
    const newSelected = currentSelected.includes(subCategory)
      ? currentSelected.filter((item) => item !== subCategory)
      : [...currentSelected, subCategory];

    setState((prevState) => ({
      ...prevState,
      selectedCategories: {
        ...prevState.selectedCategories,
        [mainCategory]: newSelected,
      },
    }));
  };

  return (
    <div>
      {PUNE_CATEGORIES.map((category, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography>{category.MainCategory}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset" fullWidth>
              {/* <FormGroup>
                {category.Categories.map((subCategory) => (
                  <FormControlLabel
                    key={subCategory}
                    control={
                      <Checkbox
                        checked={
                          state.selectedCategories[
                            category.MainCategory
                          ]?.includes(subCategory) || false
                        }
                        onChange={() =>
                          handleSubCategoryChange(
                            category.MainCategory,
                            subCategory
                          )
                        }
                      />
                    }
                    label={subCategory}
                    sx={{
                      ml: 2, // Adds left margin for spacing
                      "& .MuiTypography-root": {
                        // Targets the label's typography
                        fontSize: "0.875rem", // Smaller text size
                      },
                    }}
                  />
                ))}
              </FormGroup> */}
            </FormControl>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default CategoryAccordion;
