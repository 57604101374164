import React, { useEffect, useState } from "react";
import { Stage, Layer, Line } from "react-konva";
import DxfParser from "dxf-parser";

const DXFViewer = () => {
  const dxfFilePath =
    "https://raw.githubusercontent.com/gdsestimating/three-dxf/master/sample/data/demo.dxf";
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    // Function to load and parse the DXF file
    const loadDXF = async () => {
      try {
        const response = await fetch(dxfFilePath);
        const dxfText = await response.text();

        const parser = new DxfParser();
        const dxfData = parser.parseSync(dxfText);

        // Extract relevant entities (e.g., LINES)
        const lines = dxfData.entities.filter(
          (entity) => entity.type === "LINE"
        );
        setEntities(lines);
      } catch (error) {
        console.error("Error loading or parsing DXF file:", error);
      }
    };

    loadDXF();
  }, [dxfFilePath]);

  return (
    <Stage width={window.innerWidth} height={window.innerHeight}>
      <Layer>
        {entities.map((line, index) => (
          <Line
            key={index}
            points={[
              line.vertices[0].x,
              line.vertices[0].y,
              line.vertices[1].x,
              line.vertices[1].y,
            ]}
            stroke="black"
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default DXFViewer;
