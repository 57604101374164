import React, { useState } from "react";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import ProfileInfoCard from "../../../../widgets/ProfileInfoCard";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import ProfessionalInfoEdit from "./edit/ProfessionalInfoEdit";

const Professional = ({ profile, isView = false, setProfile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const onEdit = () => {
    setShowEdit(true);
  };
  return (
    <div className="profile-info-c">
      <RdMyModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        modalC={
          <ProfessionalInfoEdit
            profile={profile}
            setProfile={setProfile}
            setShow={setShowEdit}
          />
        }
      />
      <HeaderLabel
        showEdit={!isView}
        onEdit={onEdit}
        label="Professional Information"
      />
      <div className="rd-items-three">
        <ProfileInfoCard
          infoCard={{
            label: "Industry Sector",
            value: profile.industrySector,
          }}
        />
        <ProfileInfoCard
          infoCard={{ label: "Department", value: profile.department }}
        />
        <ProfileInfoCard
          infoCard={{
            label: "Event Info Source",
            value: profile.eventDiscovery,
          }}
        />
      </div>
    </div>
  );
};

export default Professional;
