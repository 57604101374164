import { createContext, useState } from "react";

const MobileContext = createContext();
const MobileProvider = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <MobileContext.Provider value={{ mobileMenuOpen, setMobileMenuOpen }}>
      {children}
    </MobileContext.Provider>
  );
};
export { MobileContext, MobileProvider };
